import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { catchError, take, timeout } from 'rxjs/operators';
interface municipiosModel {
  CORP_CIDADE: any[]; // You can replace 'any' with a more specific type if you have one
}
@Injectable({
  providedIn: 'root',
})
export class SatDatabaseService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': '*',
      'Access-Control-Allow-Headers': '*'

    })
  }

  baseURL = "prodata/"

  public areasLoaded: any = new BehaviorSubject<boolean>(false);
  public areasArr: any = []
  private areasArrOriginal: any = []
  constructor(private http: HttpClient) {
    this.areasLoaded.next(false)
    this.getAreasTemas()
    console.log('databaseService', 'this.areasLoaded', this.areasLoaded)
  }

  initializeService(): void {
    this.areasLoaded.next(false);
    this.getAreasTemas();
    console.log('databaseService', 'this.areasLoaded', this.areasLoaded);
  }

  getAreasTemas() {
    this.http.get<any>(this.baseURL + 'ListController/ListAreaTema', { responseType: 'json' }).pipe(
      take(1)
    ).subscribe((json: any) => {
      console.log(json.AreaServTec);
      this.areasArrOriginal = [...new Set(json.AreaServTec)]
      this.areasArr = json.AreaServTec;
      this.areasLoaded.next(true);
    });
  }

  getOriginalAreasTemas() {
    let areasArr = [...new Set(this.areasArrOriginal)]
    console.log(areasArr);
    return areasArr
  }

  getMembroByMatricula(matricula: any) {
    return this.http.get<any>(this.baseURL + 'ListController/ListaMembrosMatricula?matricula=' + matricula, { responseType: 'json' });
    return this.http.get<any>('ListController/ListaMembrosMatricula?matricula=' + matricula, { responseType: 'json' });
  }

  getProcedimentosByMatricula(matricula: any) {
    return this.http.get<any>(this.baseURL + 'ListController/ListaProcMatricula?matricula=' + matricula, { responseType: 'json' });
  }

  getProcNR(nunDocAndOrigem: any) {
    return this.http.get<any>(this.baseURL + 'ListController/ListaProcNR?NrProc=' + nunDocAndOrigem.NrProc + '&psso_dk=' + nunDocAndOrigem.psso_dk, { responseType: 'json' });
  }

  getMunicipios() {
    return this.http.get<municipiosModel>('../../assets/data/municipios.json');
  }


  getItsByOrgiDk(orgiDk: any) {
    return this.http.get<any>(this.baseURL + 'ListController/ListaInfoTecnica?orgiDk=' + orgiDk, { responseType: 'json' });
  }

  getTemas() {
    return this.http.get<any>(this.baseURL + 'ListController/ListTemaComp', { responseType: 'json' });
  }

  getTemasServicos() {
    return this.http.get<any>(this.baseURL + 'ListController/ListTemaComp', { responseType: 'json' });
  }

  getPrioridades() {
    return this.http.get<any>(this.baseURL + 'ListController/ListaTpPrioridade', { responseType: 'json' });
  }

  getPrefLegais() {
    return this.http.get<any>(this.baseURL + 'ListController/ListaPreferenciaLegal', { responseType: 'json' });
  }

  createSateOLD(data: any) {
    return this.http.post<any>(this.baseURL + 'SolAnaliseTecnica/Create', data);
  }

  createSate(data: any): Observable<HttpEvent<any>> {
    return this.http.post(this.baseURL + 'SolAnaliseTecnica/CreateAnexo', data, {
      reportProgress: true, // Enable progress events
      observe: 'events' // Receive all types of events
    });
  }

  createAnexoSate(data: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json; charset=UTF-8')
      .set('Accept', 'application/json');


    // Adjust the timeout value as needed (in milliseconds)
    const timeoutValue = 18000 * 1000; // 1 seconds

    return this.http.post(this.baseURL + 'SolAnaliseTecnicaAnexo/Create', data, {
      headers: headers,
      reportProgress: true,
      observe: 'events',
    }).pipe(
      // Set a timeout for the request
      timeout(timeoutValue),
      catchError((error: HttpErrorResponse) => {
        // Handle errors here
        return throwError(error);
      })
    );
  }

  uploadAnexos(data: any, sateDK: any): Observable<HttpEvent<any>> {
    console.log(data)
    const timeoutValue = 18000 * 1000; // 1 seconds
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json; charset=UTF-8')
      .set('Accept', 'application/json');

    return this.http.post(this.baseURL + 'Anexos/InsertAnexo?sateDk=' + sateDK, data, {
      reportProgress: true, // Enable progress events
      observe: 'events',
      headers: headers,
      responseType: 'json'
    }).pipe(
      // Set a timeout for the request
      timeout(timeoutValue),
      catchError((error: HttpErrorResponse) => {
        // Handle errors here
        return throwError(error);
      })
    );

  }


  createRascunho(data: any) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json; charset=UTF-8')
      .set('Accept', 'application/json');

    return this.http.post(this.baseURL + 'SolAnaliseTecnica/CreateRacunho', data, {
      headers: headers,
      responseType: 'json'
    }).pipe(
      // Set a timeout for the request
      catchError((error: HttpErrorResponse) => {
        // Handle errors here
        return throwError(error);
      })
    );
  }

  // LISTA E DETALHES DA SAT


  listAllSatsByServidorMat(servidorMat: any) {
    // let matTeste: any = '50000114'
    return this.http.get<any>(this.baseURL + 'SolAnaliseTecnica/ListSatByMatriculaSatDto?matricula=' + servidorMat, { responseType: 'json' });
  }
  listAllSatsByMembroMat(membroMat: any) {
    return this.http.get<any>(this.baseURL + 'SolAnaliseTecnica/ListSatByMatriculaMembroDto?matricula=' + membroMat, { responseType: 'json' });
  }

  listAllSatsByOrgi(orgi_DK: any) {
    return this.http.get<any>(this.baseURL + 'SolAnaliseTecnica/ListSatByOrgiDkDto?orgiDK=' + orgi_DK, { responseType: 'json' });
  }
  listAllSats() {
    return this.http.get<any>(this.baseURL + 'SolAnaliseTecnica/ListaSatsPG?siza=1000' , { responseType: 'json' });
  }

  submeterSat(sateDK: any) {
    return this.http.put(this.baseURL + 'SolAnaliseTecnica/aprovaSat?SAST_DK=' + sateDK, null);
  }

  editarSatdd(sateDK: any) {
    return this.http.put(this.baseURL + '/EditController/Editar?sateDk=' + sateDK, null);
  }

  editarSat(data: any): Observable<any> {
    console.log(data.sateDk)
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json; charset=UTF-8')
      .set('Accept', 'application/json');


    // Adjust the timeout value as needed (in milliseconds)
    const timeoutValue = 18000 * 1000; // 1 seconds

    return this.http.put(this.baseURL + 'EditController/Editar?sateDk=' + data.sateDk, data, {
      headers: headers,
      reportProgress: true,
      observe: 'events',
    }).pipe(
      // Set a timeout for the request
      timeout(timeoutValue),
      catchError((error: HttpErrorResponse) => {
        // Handle errors here
        return throwError(error);
      })
    );
  }


  excluirSat(sateDK: any) {
    return this.http.delete(this.baseURL + 'UpdtDellController/deleteSat?SAT_DK=' + sateDK);
  }

  getSatDetalhes(sateDK: any) {
    return this.http.get<any>(this.baseURL + 'EditController/FrmEditar?sateDk=' + sateDK, { responseType: 'json' });
  }

  getAnexosSatsByDk(satDk: any) {
    return this.http.get<any>(this.baseURL + 'SolAnaliseTecnicaAnexo/ListaBySateDk?sateDk=' + satDk, { responseType: 'json' });
  }

  downloadAllFile(satDk: any): Observable<any> {
    const url = `${this.baseURL}Anexos/downloadAllFile?sateDk=${satDk}`;
    return this.http.get(url, { responseType: 'json' });
  }

  downloadAnexoBySaaxDk(preUrl: any) {
    const url = preUrl.substring(1);
    return this.http.get<any>(url, { responseType: 'json' });
  }

  getOrgaoAtuacao(ticket: any): Observable<any> {

    var reqHeader = new HttpHeaders({
      "ticket": ticket,
      "Content-Type": "application/json",
      "Accept": "*/*"
    });

    let options2 = { headers: reqHeader };
    let baseUrl = '/prodata-ws/';
    const url = baseUrl + 'user/orgao-atuacao';
    return this.http.get<any>(url, options2).pipe(catchError(error => of(null)));
  }




}
