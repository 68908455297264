<div fxLayout="row wrap" style="height: calc(100vh - 154px);">

  <div fxFlex="100" [fxFlex.gt-sm]="'70'">
    <div>
      <div>

        <div class="form-container" @fade>
          <div style="margin-top:90px">
            <mat-progress-bar
                              *ngIf="inTransition === true"
                              color="#ad8847"
                              mode="indeterminate"
                              style="width: 400px; margin: auto">
            </mat-progress-bar>

            <div class="title">
              <div class="step-title">
                {{satFormService.stepsSequenceOriginal[satFormService.currentStepIndex].label}}
              </div>
            </div>


            <ng-container [ngSwitch]="satFormService.stepToLoad">
              <app-solicitante *ngSwitchCase="'solicitante'"></app-solicitante>
              <app-procedimento *ngSwitchCase="'procedimento'"></app-procedimento>

              <app-complementacao *ngSwitchCase="'complementacao'"></app-complementacao>
              <app-temas-servicos *ngSwitchCase="'temas-servicos'"></app-temas-servicos>
              <app-complementaridade *ngSwitchCase="'complementaridade'"></app-complementaridade>
              <app-prioridade *ngSwitchCase="'prioridade'"></app-prioridade>
              <app-documentos-upload *ngSwitchCase="'documentos-upload'"></app-documentos-upload>
              <app-documentos-integra *ngSwitchCase="'documentos-integra'"></app-documentos-integra>
              <app-duvida *ngSwitchCase="'duvida'"></app-duvida>
              <app-resumo *ngSwitchCase="'resumo'"></app-resumo>
              <app-envio *ngSwitchCase="'enviarSat'"></app-envio>
              <!-- Add cases for other components -->


            </ng-container>
          </div>
          <!--     NAVIGATION BUTTONS -->

          <div
               *ngIf="satFormService.stepToLoad !== 'enviarSat'"

               style=" margin-top: 40px; display: flex; justify-content: space-between">
            <button *ngIf="satFormService.stepToLoad !== 'solicitante'"
                    [disabled]="satFormService.navegacao.voltarDisabled == true"
                    (click)="satFormService.changeStep('back');scrollToTop()"
                    mat-flat-button

                    style="flex-grow: 1; margin-right: 15px; border: solid 1px #C9C9C9; background-color: transparent;">
              Voltar
            </button>
            <button
                    [disabled]="satFormService.navegacao.continuarDisabled == true"
                    *ngIf="satFormService.stepToLoad !== 'resumo'"
                    (click)="satFormService.changeStep('forward'); scrollToTop()"
                    mat-flat-button
                    color="accent"
                    style="flex-grow: 1; margin-left: 15px;"
                    [style.margin-left]="satFormService.stepToLoad === 'solicitante' ? '0px' : '15px'"
                    [style.margin-right]="satFormService.stepToLoad === 'solicitante' ? '15px' : '0px'"
                    [style.max-width]="satFormService.stepToLoad === 'solicitante' ? '290px' : null">
              Continuar
            </button>
            <button
                    [disabled]="satFormService.navegacao.continuarDisabled == true "
                    *ngIf="satFormService.stepToLoad === 'resumo'  &&  satFormService.isEdicao === false"
                    (click)="satFormService.changeStep('forward');scrollToTop()"
                    mat-flat-button
                    color="accent"
                    style="flex-grow: 1; margin-left: 15px;">
              {{satFormService.logadoIsMembro === true ? 'Enviar SAT' : 'Criar SAT'}}
            </button>
            <button
                    [disabled]="satFormService.navegacao.continuarDisabled == true "
                    *ngIf="satFormService.stepToLoad === 'resumo'  &&  satFormService.isEdicao === true"
                    (click)="satFormService.changeStep('forward');scrollToTop()"
                    mat-flat-button
                    color="accent"
                    style="flex-grow: 1; margin-left: 15px;">
              Salvar edição
            </button>



            <!--  <button

                    (click)="satFormService.setPreformToTest();scrollToTop()"
                    mat-flat-button
                    color="warn">
              testar
            </button> -->

            <!--  <button
                    (click)="satFormService.enviarSat()"
                    mat-flat-button
                    color="warn">
              Enviar SAT
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button> -->
          </div>
          <div style="    margin-top: 30px;
          ">

            <button

                    *ngIf="satFormService.stepToLoad !== 'resumo' && satFormService.stepToLoad !== 'enviarSat' &&  satFormService.isEdicao === true"
                    (click)="satFormService.cancelarEdicao()"
                    mat-button
                    color="accent"
                    style="max-width: 250px;margin-left:0px; flex-grow: 1;"
                    [style.max-width]="satFormService.stepToLoad === 'solicitante' ? '290px' : null">
              Cancelar edição
            </button>
            <button

                    *ngIf="satFormService.stepToLoad !== 'resumo' && satFormService.stepToLoad !== 'enviarSat' &&  satFormService.isEdicao === true"
                    (click)="satFormService.navigateToStepByStepName('resumo'); scrollToTop()"
                    mat-stroked-button
                    color="accent"
                    style="max-width: 250px;margin-left:30px; flex-grow: 1;"
                    [style.max-width]="satFormService.stepToLoad === 'solicitante' ? '290px' : null">
              Finalizar edição
            </button>


          </div>

          <div class="text-valid" *ngIf="satFormService.navegacao.textToContinue !== ''">
            * {{satFormService.navegacao.textToContinue}}
          </div>
        </div>





      </div>
    </div>

  </div>

  <div fxFlex="100" fxFlex.gt-sm="30">
    <div class="w-full h-full d-flex align-items-center justify-content-center">
      <div class="steps-inicio">
        <div
             class="step-inicio"
             *ngFor="let step of satFormService.stepsSequenceOriginal; let i = index; let isLast = last">
          <div style="position: relative; top: 2px;"
               [style.cursor]="step.valid === 'null' || satFormService.stepToLoad === 'enviarSat' ? '' : 'pointer'"
               (click)="step.valid === 'null' || satFormService.stepToLoad === 'enviarSat' ? '' : satFormService.navigateToStepByStepName(step.step_NM);scrollToTop()">
            <span matTooltip="{{ step.step_DS }}" matTooltipClass="tooltip-accent"
                  matTooltipPosition="left"
                  [ngClass]="step.step_NM === this.satFormService.stepToLoad ? 'n-step-ativo' : step.valid === 'null' || satFormService.stepToLoad === 'enviarSat' ? 'n-step-null' : step.valid === 'S' ? 'n-step-valid' : 'n-step-invalid'">{{
              i + 1 }}</span><span class="txt-step"
                  [ngClass]="i === 0 ? ' first' : ''">{{ step.label }}</span>
          </div>
          <div *ngIf="!isLast" style="text-align: center; width: 24px">
            <div class="line"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
