<!--  DEFINIÇÃO  DO PROCEDIMENTO PARA ATENDIMENTO (N MPRJ) ************************************************* -->
<div style="min-height: 165px; display: block">



  <!-- <div fxLayout="row" fxLayoutGap="15px">
    <mat-form-field class="example-full-width " appearance="outline">
      <input matInput [matAutocomplete]="autocSrvTec" formControlName="FCServTec"
            >
      <mat-autocomplete #autocSrvTec="matAutocomplete"
                        >
        <mat-option *ngFor="let docTec of filteredITs | async"
                    [value]="docTec.num_IT">
          {{docTec.num_IT}} </mat-option>
      </mat-autocomplete>
    </mat-form-field>

  </div> -->


  <!--   <ng-select [(ngModel)]="satFormService.preForm.procedimentoObj" [minTermLength]="5">
    <ng-option *ngFor="let infoTec of satFormService.itsComplemArr"
               [value]="infoTec.itcn_dk">{{infoTec.num_IT}}</ng-option>
  </ng-select> -->
















  <!-- PROCEDIMENTO SELECT--------------------- -->



  <div>
    <div *ngIf=" satFormService.preForm.procedimentoObj === null">
      <div><strong>Selecione a origem do procedimento.</strong></div>
      <mat-radio-group class="radio-group" [(ngModel)]="satFormService.sistemaOrigemPssoDk">
        <mat-radio-button class="radio-button"
                          (click)="origemChange(origem.pssoDk)"
                          *ngFor="let origem of sistemaOrigemArr"
                          [value]="origem.pssoDk">
          {{ origem.pssoNmSistema }}
        </mat-radio-button>
      </mat-radio-group>
      <div><strong>Busque o procedimento para o qual deseja atendimento</strong></div>

      <div class="buscar">
        <mat-form-field appearance="fill" style="width: 100%; max-width: 350px;">
          <mat-icon matPrefix>search</mat-icon>
          <input
                 type="text"
                 [(ngModel)]="satFormService.procNR"
                 matInput

                 placeholder="Ex. {{sistemaOrigemArr[satFormService.sistemaOrigemPssoDk-1].formato}}"
                 value="{{ satFormService.procNR }}" />

          <button color="accent"
                  *ngIf="satFormService.procNR"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="satFormService.procNR = ''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="spinnerProcedimentos === false && satFormService.preForm.procedimentoObj !== null">
      <h3>Procedimento</h3>
      <div class="box-border" style="margin-bottom: 20px;">
        <div class="content">
          <div>
            <strong>{{ satFormService.preForm.procedimentoObj?.num_DOC }}</strong>
          </div>
          <div>
            {{ satFormService.preForm.procedimentoObj?.origem }} - {{
            satFormService.preForm.procedimentoObj?.assunto_PROCESSO }}
          </div>
        </div>
        <!-- Trash icon button -->
        <button mat-icon-button class="delete-button" (click)="deleteProcedimento()">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </div>
    <div *ngIf="spinnerProcedimentos === true">
      <div style="margin-bottom: 15px;margin-top: 15px;"><strong>Buscando procedimento. Por favor,
          aguarde
          alguns instantes.</strong></div>
      <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>

      <!--  <p>Carregando...</p> -->
    </div>

    <div
         *ngIf="spinnerProcedimentos === false && satFormService.preForm.procedimentoObj===null && showError===true">
      <p
         style="color: #AA4139;">
        Procedimento não encontrado. Verifique o número e origem.

      </p>

    </div>
    <button *ngIf="satFormService.preForm.procedimentoObj == null"

            mat-flat-button
            color="accent"
            style="margin-top: 10px;"
            (click)="getProcNR({NrProc: satFormService.procNR, psso_dk:satFormService.sistemaOrigemPssoDk}); ">
      Buscar
      <mat-icon aria-label="Buscar">search</mat-icon>
    </button>







    <!-- <div class="text-valid"
         style="border: none; margin: 0px; margin-top: 5px; padding: 0px; text-align: left;">
      * Necessário estar com vista aberta ao membro solicitante.
    </div>
    <div>
      <mat-form-field style="width: 100% !important">
        <div *ngIf="satFormService.preForm.procedimentoObj === null" class="search-icon"
             style="position: absolute;">
          <mat-icon>search</mat-icon> Buscar
        </div>
        <mat-select
                    (selectionChange)="getItsByOrgao(); satFormService.checkPreFormToNavigate()"
                    [(ngModel)]="satFormService.preForm.procedimentoObj"
                    #procedimentoSelect
                    class="select-box">

          <mat-select-trigger>
            <div>
              <div style="margin-bottom: 3px;"> <strong>{{ procedimentoSelect.value?.num_DOC
                  }}</strong></div>
              <div matLine>
                {{ procedimentoSelect.value?.origem }} - {{
                procedimentoSelect.value?.assunto_PROCESSO }}

              </div>
            </div>
          </mat-select-trigger>

          <mat-option>
            <lib-mat-select-search
                                   ariaLabel="Buscar"
                                   [list]="procedimentosArr"
                                   [fixOnTop]="true"
                                   searchPlaceHolder="Digite o número do procedimento"
                                   [searchProperties]="['num_DOC', 'origem']"
                                   (filtered)="procedimentosFiltradosArr = $event">
            </lib-mat-select-search>
          </mat-option>

          <mat-option
                      class="procedimentos-lista"
                      *ngFor="let procedimento of procedimentosFiltradosArr"
                      [value]="procedimento">
            <div> <strong>{{ procedimento?.num_DOC }}</strong>
            </div>
            <div>
              {{ procedimento?.origem }} - {{ procedimento?.assunto_PROCESSO }}

            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div> -->

  </div>


  <!-- MUNICIPIO SELECT--------------------- -->


  <div class="input-box" style="min-height: 165px; display: block"
       *ngIf="satFormService.preForm.procedimentoObj !== null">
    <p><strong>Selecione o(s) município(s) do local do fato</strong> <mat-icon class="info"
                matTooltipClass="tooltip-info"
                matTooltip="Se o impacto for regional, favor selecionar todos os municípios envolvidos. Se for estadual, selecionar 'Estado do Rio de Janeiro'"
                aria-label="Info">info</mat-icon>

    </p>
    <div style="font-size: 12px;
    color: #7f8081;">

      <span
            *ngIf="satFormService.preForm.municipiosArr.length > 0 ">
        <span *ngFor="let municipio of satFormService.preForm.municipiosArr; let isLast = last">
          {{municipio.CIDA_NM_CIDADE }}{{isLast ? "" : ", "}}</span></span>

    </div>
    <div *ngIf="spinnerMunicipios === true">
      <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
      <!-- <p>Carregando...</p> -->
    </div>
    <div *ngIf="spinnerMunicipios === false" class="buscar">
      <mat-form-field appearance="fill"
                      style="width: calc(100% - 7px); ">
        <mat-icon matPrefix>search</mat-icon>
        <input type="text"
               placeholder="Buscar"
               matInput
               [(ngModel)]="searchMunicipio"
               (input)="filterMunicipios(searchMunicipio)" />
      </mat-form-field>
    </div>


    <div style="max-height: 250px; overflow: auto; overflow-x: hidden">
      <mat-list role="list">
        <mat-list-item
                       *ngFor="let municipio of filteredMunicipios"
                       class="lista clickable-item"

                       [ngClass]="{ selected: municipio.selected }"
                       role="listitem">

          <p matLine style="font-size: 11px;">
            <mat-checkbox style="width: 100%;" [(ngModel)]="municipio.selected"
                          (change)="selectMunicipio(municipio)"
                          [disabled]="disableSelect === true && municipio.CIDA_NM_CIDADE !== 'ESTADO DO RIO DE JANEIRO'">{{
              municipio.CIDA_NM_CIDADE }}</mat-checkbox>
          </p>
        </mat-list-item>
      </mat-list>
    </div>
  </div>






  <!-- COMPLEMENTACAO SELECT--------------------- -->
  <div style="margin-top: 62px;"
       *ngIf="satFormService.itsComplemArr.length > 0 && satFormService.preForm.municipiosArr.length > 0 && satFormService.preForm.procedimentoObj !== null">
    <p><strong>Esta SAT é
        complementação de IT anterior?</strong></p>
    <div>
      <p
         style="color: #AA4139; font-size: 14px;">
        Para IT´s produzidas antes de OUT/2018 marque <strong>"NÃO"</strong> e descreva seu número
        no Objetivo da solicitação.
    </div>

    <div>
      <button mat-stroked-button class="bt-opcoes"
              (click)="satFormService.preForm.complementacaoObj.isComplementacao = 'N';satFormService.preForm.complementacaoObj.docTecAnteriorObj = null; satFormService.checkPreFormToNavigate()"
              [ngClass]="{'selected-button': satFormService.preForm.complementacaoObj.isComplementacao === 'N'}">
        Não
      </button>

      <button mat-stroked-button class="bt-opcoes"
              (click)="satFormService.preForm.complementacaoObj.isComplementacao = 'S'; satFormService.checkPreFormToNavigate()"
              [ngClass]="{'selected-button': satFormService.preForm.complementacaoObj.isComplementacao === 'S'}">
        Sim
      </button>
    </div>
  </div>



  <!--
  <mat-button-toggle-group
                           aria-label="Font Style"
                           [(ngModel)]="satFormService.preForm.complementacaoObj.isComplementacao">
    <mat-button-toggle [value]="false"
                       (click)="satFormService.onToggleComplementacao(false)">Não</mat-button-toggle>
    <mat-button-toggle [value]="true"
                       (click)="satFormService.onToggleComplementacao(true)">Sim</mat-button-toggle>
  </mat-button-toggle-group> -->

  <!-- <div *ngIf="showNenhumDocumento === true && spinnerItsComplem === false">
    <p><span class="destaque">Nenhum documento técnico encontrado.</span></p>
  </div> -->
  <div
       *ngIf="satFormService.preForm.complementacaoObj.isComplementacao === 'S'  && satFormService.preForm.municipiosArr.length > 0 ">
    <div style="height: 30px; margin-top:20px" *ngIf="spinnerItsComplem === true">
      <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
      <!-- <p>Carregando...</p> -->
    </div>



    <div
         *ngIf="satFormService.itsComplemArr.length > 0 && satFormService.preForm.procedimentoObj !== null">


      <div class="input-box" *ngIf="spinnerItsComplem === false">


        <div class="buscar"
             *ngIf=" this.satFormService.preForm.complementacaoObj.docTecAnteriorObj === null">
          <p>Busque o documento técnico a ser complementado:</p>
          <mat-form-field appearance="fill" style="width: calc(100% - 7px);">
            <mat-icon matPrefix>search</mat-icon>
            <input type="text"
                   placeholder="Busque pelo número da IT"
                   matInput
                   [(ngModel)]="searchITNumero"
                   (keyup)="filterITs($event)" />
            <button color="accent"
                    *ngIf="searchITNumero"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="searchITNumero = '' ; filterITs($event)">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <div style="max-height: 250px;
          overflow: auto;
          overflow-x: hidden; font-size: 14px;">
            <div style="height: 30px; margin-top:20px" *ngIf="spinnerSearchIT === true">
              <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
              <!-- <p>Carregando...</p> -->
            </div>

            <div *ngFor="let it of itsComplemFiltradosArr "
                 (click)="satFormService.preForm.complementacaoObj.docTecAnteriorObj = it ; satFormService.checkPreFormToNavigate()"
                 class="lista clickable-item">
              <div matLine><strong>IT n.: </strong>{{ it.num_IT }}</div>
              <div matLine><strong>MPRJ n.: </strong>{{ it.mprj }}</div>
            </div>
          </div>


        </div>



        <div
             *ngIf="spinnerProcedimentos === false && satFormService.preForm.complementacaoObj.docTecAnteriorObj !== null">
          <div class="box-border" style="margin-bottom: 20px; margin-top: 20px;">
            <div class="content" style="font-size: 14px !important;">
              <div matLine><strong>IT n.: </strong>{{
                satFormService.preForm.complementacaoObj.docTecAnteriorObj.num_IT }}</div>
              <div matLine><strong>MPRJ n.: </strong>{{
                satFormService.preForm.complementacaoObj.docTecAnteriorObj.mprj }}</div>
            </div>
            <!-- Trash icon button -->
            <button mat-icon-button class="delete-button"
                    (click)=" this.satFormService.preForm.complementacaoObj.docTecAnteriorObj = null ; satFormService.checkPreFormToNavigate()">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>



        <!--   <mat-form-field style="width: 100% !important">
          <div *ngIf="satFormService.preForm.complementacaoObj.docTecAnteriorObj === null"
               class="search-icon"
               style="position: absolute;">
            <mat-icon>search</mat-icon> Buscar
          </div>
          <mat-select
                      (selectionChange)="satFormService.checkPreFormToNavigate()"
                      style="width: 100%"
                      class="select-box"
                      [(ngModel)]="satFormService.preForm.complementacaoObj.docTecAnteriorObj"
                      #itComplemSelect>
            <mat-select-trigger>{{ itComplemSelect.value?.num_IT }}</mat-select-trigger>

            <mat-option>
              <lib-mat-select-search
                                     [list]="satFormService.itsComplemArr"
                                     [fixOnTop]="true"
                                     searchPlaceHolder="Selecione o documento"
                                     [searchProperties]="['num_IT']"
                                     (filtered)="itsComplemFiltradosArr = $event">
              </lib-mat-select-search>
            </mat-option>

            <mat-option
                        class="procedimentos-lista"
                        *ngFor="let docTec of itsComplemFiltradosArr"
                        [value]="docTec">
              <div>
                <div matLine><strong>IT n.: </strong>{{ docTec.num_IT }}</div>
                <div matLine><strong>MPRJ n.: </strong>{{ docTec.mprj }}</div>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field> -->
      </div>
    </div>
  </div>
</div>
