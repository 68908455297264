<div class="list-container">
  <div class="matero-page-header-inner">
    <h1 class="matero-page-title">Lista de SAT's </h1>
    <p>Atribuídas <strong>{{ usrLogado.activeRole === 'Membro' ? ' ao Membro' :
        (usrLogado.activeRole === 'Administrador' ? ' ao Administrador' : ' a(o) servidor(a)') }}:
        {{usrLogado.name}} -
        {{usrLogado.matricula | slice:4}}</strong> </p>
    <!--  <breadcrumb *ngIf="!hideBreadcrumb" [nav]="nav"></breadcrumb> -->
  </div>
  <div *ngIf="logadoIsMembro === true && this.orgiArr.length > 1"
    style="padding:12px 6px;margin-bottom: 15px;"> <mat-chip-list
                aria-label="Seleção de Promotoria">
   <span style="font-size: 21px; font-weight: 500; margin-right: 19px;">Filtrar por </span>

   <mat-chip *ngFor="let orgi of orgiArr" (click)="getListSats(orgi.id); "
             [matTooltip]="orgi.nome"
             [selected]="orgi.selected===true" color="accent">
     {{ orgi.nome.length > 30 ? (orgi.nome | slice:0:15) + '...' +(orgi.nome | slice:-15) :
     orgi.nome }}
   </mat-chip>
 </mat-chip-list>
</div>
  <div fxLayout  style="padding-bottom: 20px;" *ngIf="spinner === false">
    <div fxLayoutAlign="left">
      <p style="font-size: 21px; font-weight: 500; margin-right: 19px;">Filtrar por </p>
    </div>
    <div fxLayout fxLayoutAlign="center center">
      <div>
        <button (click)="doFilter('ALL')" class="buttonFilter"  [style.background-color]="'#104861'">TODAS</button>
      </div>
      <div>
        <button (click)="doFilter('APROVACAO')" class="buttonFilter"
                [style.background-color]="'#AA4139'">Aguardando aprovação</button>
      </div>
      <div>
        <button (click)="doFilter('SEMAD')" class="buttonFilter"
                [style.background-color]="'#DB9027'">Em Admissibilidade</button>
      </div>
      <div>
        <button (click)="doFilter('SPE')" class="buttonFilter"
                [style.background-color]="'#489487'">Priorizada na Equipe Técnica</button>
      </div>
      <div>
        <button (click)="doFilter('SEA')" class="buttonFilter"
                [style.background-color]="'#519448'">Em andamento</button>
      </div>
      <div>
        <button (click)="doFilter('SCI')" class="buttonFilter"
                [style.background-color]="'#1F2022'">IT(s) produzida(s)</button>
      </div>

    </div>
  </div>


  <div *ngIf="spinner === true">
    <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
    <!--  <p>Carregando...</p> -->
  </div>
  <div *ngIf="spinner === false">
    <div *ngIf="satsArr.length === 0 && spinner === false">
      <p>Nenhuma SAT encontrada para este usuário</p>
    </div>

    <mat-card [@fadeInAnimation] matRipple class="sat-card"
              *ngFor="let sat of satsArr " (click)="openDialog(sat)">

      <div class="info-tag " *ngIf="sat.sateInAprovada === 'N'"
           [style.background-color]="'#AA4139'">
        <span>Aguardando aprovação do Membro</span>
      </div>
      <div class="info-tag "
           *ngIf="sat.sateInAprovada === 'S' && (sat.itProduzida == 'SENVG') "
           [style.background-color]="'#537B8D'">
        <span>Enviada para o GATE em: {{sat.sateDtAprovacao | date:'dd/MM/yyyy HH:mm'}}h</span>
      </div>
      <div class="info-tag " *ngIf="sat.sateInAprovada === 'S' && sat.itProduzida == 'SPE' "
           [style.background-color]="'#489487'">
        <span>Priorizada na Equipe Técnica</span>
      </div>
      <div class="info-tag " *ngIf="sat.sateInAprovada === 'S' && sat.itProduzida == 'SEA' "
           [style.background-color]="'#519448'">
        <span>Em andamento</span>
      </div>
      <div class="info-tag " *ngIf="sat.sateInAprovada === 'S' && sat.itProduzida == 'SCEP' "
           [style.background-color]="'#A13C22'">
        <span>{{sat?.itpsProdataSei?.itpsIdProtocoloEstado}}</span>
      </div>
      <div class="info-tag " *ngIf="sat.sateInAprovada === 'S' && sat.itProduzida == 'SEMAD' "
           [style.background-color]="'#DB9027'">
        <span>{{sat?.itpsProdataSei?.itpsIdProtocoloEstado}}</span>
      </div>
      <div class="info-tag " *ngIf="sat.sateInAprovada === 'S' && sat?.itpsProdataSei?.length <= 0"
        [style.background-color]="'#A13C22'">
      <span>"Aguardando Integração"</span>
      </div>

      <div class="info-tag " *ngIf="sat.sateInAprovada === 'S' && sat.itProduzida == 'SDS' && sat?.itpsProdataSei?.length > 0"
           [style.background-color]="'#A13C22'">
        <span>{{sat?.itpsProdataSei.itpsIdProtocoloEstado}}</span>
      </div>
      <div class="info-tag " *ngIf="sat.sateInAprovada === 'S' && sat.itProduzida == 'SCI' "
           [style.background-color]="'#1F2022'">
        <span>IT(s) produzida(s)</span>
      </div>


      <div style="padding: 6px; text-align: center;" *ngIf="satAlteradaDk === sat.sateDk">
        <mat-spinner [diameter]="40" style="margin: auto !important; width: 40px; height: 40px; "
                     color="accent"></mat-spinner>
        <div style="text-align: center; margin:6px; font-size: 11px;">Atualizando SAT...</div>
      </div>

      <mat-card-content *ngIf="satAlteradaDk !== sat.sateDk" style="height: 137px; padding: 11px;">
        <!--  <div>
          <span style="font-size: 11px; float: right;margin-bottom: 11px;">Cadastro em:
            {{sat.sateDtCadastro | date:
            'dd/MM/yyyy HH:mm'}}h</span>
        </div> -->

        <p>
          <span
                style=" padding: 3px 6px; background-color: #EFF1F2; border-radius: 3px;"><strong>Número
              PA/IC: </strong>{{ sat.sateNumDocumento }}</span>
        </p>

        <div style="font-size: 13px; ">
          <p style="margin-bottom: 4px; margin-top:4px;"><strong>Objetivo: </strong>
            {{ sat.sateDsDuvida.length > 50
            ? (sat.sateDsDuvida | slice:0:100) + '...' :
            sat.sateDsDuvida }}</p>
          <p *ngIf="sat.sateOrgiDkList.length>0"
             [matTooltip]="sat.sateOrgiDkList[0].nome" style="margin-bottom: 6px; margin-top:6px;">
            <strong>Promotoria: </strong><span
                  style="font-size: 12px;">{{sat.sateOrgiDkList[0].abrev}}</span>

          </p>

          <p style="margin-bottom: 6px; margin-top:6px;" *ngIf="sat.sateInAprovada === 'S'">
            <strong>SAT:
            </strong>{{sat?.sateNrSat}}
          </p>

        </div>
        <p style="margin-bottom: 6px; margin-top:4px;" *ngIf="sat.sateInAprovada === 'S'  ">
          <strong>Fila:
          </strong>{{sat?.itpsProdataSei?.itpsIdProtocoloEstado}}
        </p>


      </mat-card-content>
    </mat-card>
  </div>
</div>
