import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, take } from 'rxjs';
import { SatDatabaseService } from '../../shared/services/sat-database.service';
import { SatValidarService } from './sat-validar.service';
//import { SatAnexosService } from '@shared';
import { LocalStorageService } from '@shared';
import * as moment from 'moment';
import { SatDetalhesComponent } from '../sat/sat-detalhes/sat-detalhes.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root',
})



export class SatFormService {
  firstLoad = true
  preForm: any = null;
  stepToLoad: any = 'solicitante';
  stepsLoaded: any = [];

  navegacao = { 'voltarDisabled': true, 'continuarDisabled': true, 'textToContinue': '' }

  logadoIsMembro = true
  matriculaSCA = ''
  membroNaoEncontrado = false

  editarSateDk = false
  editarSatAnexoNextOrder = 1

  public procedimentosLoaded: any = new BehaviorSubject<boolean>(false);
  procedimentosUsuSolicit: any = [];

  public procedimentoNRLoaded: any = new BehaviorSubject<boolean>(false);
  sistemaOrigemPssoDk = 1
  procNR: any
  procNROriginal: any

  public municipiosLoaded: any = new BehaviorSubject<boolean>(false);
  municipiosArr: any = [];

  public itsComplemLoaded: any = new BehaviorSubject<boolean>(false);
  itsComplemArr: any = [];
  filteredITs: any = Observable<any[]>;

  public temasLoaded: any = new BehaviorSubject<boolean>(false);
  areasArr: any = [];


  public temasServicosLoaded: any = new BehaviorSubject<boolean>(false);
  temasServicosArr: any = [];
  tmsDkArray: any = []; // somente editar

  public prioridadesLoaded: any = new BehaviorSubject<boolean>(false);
  prioridadesArr: any = [];

  public prefLegaisLoaded: any = new BehaviorSubject<boolean>(false);
  prefLegaisArr: any = [];

  membrosAtivos: any = [];

  solicitanteMat: any = null;

  isIntegra: boolean = false;
  idsIntegraEditar: any = []
  stepsSequenceOriginal = [
    {
      label: 'Membro solicitante',
      step_NM: 'solicitante',
      step_DS: 'Definição do Membro solicitante',
      order: 1,
      valid: 'null'
    },
    {
      label: 'Dados do procedimento',
      step_NM: 'procedimento',
      step_DS: 'Seleção do procedimento a ser atendido',
      order: 2,
      valid: 'null'
    },
    {
      label: 'Temas e serviços técnicos',
      step_NM: 'temas-servicos',
      step_DS: 'Definição dos temas e serviços técnicos',
      order: 3,
      valid: 'null'
    },
    { label: 'Upload de documentos', step_NM: 'documentos-upload', step_DS: 'Documentos vinculados aos Serviços Técnicos', order: 4, valid: 'null' },
    {
      label: 'Complementaridade',
      step_NM: 'complementaridade',
      step_DS: 'Definição da Complementaridade',
      order: 5,
      valid: 'null'
    },
    { label: 'Atendimento prioritário', step_NM: 'prioridade', step_DS: 'Definição da prioridade', order: 6, valid: 'null' },

    { label: 'Objetivo da solicitação', step_NM: 'duvida', step_DS: 'Definição da dúvida técnica', order: 7, valid: 'null' },
    { label: 'Confirmação dos dados', step_NM: 'resumo', step_DS: 'Confirmação dos dados da SAT preeenchida', order: 8, valid: 'null' },
    { label: 'Envio', step_NM: 'enviarSat', step_DS: 'Envio da SAT preenchida', order: 9, valid: 'null' },
  ];
  stepsSequence = this.stepsSequenceOriginal;
  currentStepIndex = 0;

  anexosArr: any = []

  satState = { status: 'naoEnviada', error: null, sateDk: null }
  uploadProgress: number = 0;
  uploadFileProgress: number = 0;
  messagefile:string ="";
  sateRascunho: any = {
    rsatCdmatricula: "00008521",
    rsatDtAtualizacao: "",
    rsatRascunho: []
  }

  isEdicao = false

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private satDatabaseService: SatDatabaseService,
    //public satAnexosService:SatAnexosService,
    private toast: ToastrService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private satValidarService: SatValidarService) {
    this.procedimentosLoaded.asObservable();
    this.localStorageService.satFiles = []



  }

  // NAVIGATION THROUGH STEPS *******************************

  changeStep(direction: string) {
    this.stepsSequence[this.currentStepIndex]['valid'] = "S"
    if (direction === 'forward') {
      if (this.currentStepIndex < this.stepsSequence.length - 1) {
        this.currentStepIndex++;
      }
    }
    if (direction === 'back') {
      if (this.currentStepIndex > 0) {
        this.currentStepIndex--;
      }
    }
    this.stepToLoad = this.stepsSequence[this.currentStepIndex].step_NM;
    if (this.stepsSequence[this.currentStepIndex]['valid'] !== "S") {
      this.navegacao.continuarDisabled = true;
    }

    this.preForm.satFiles = this.localStorageService.satFiles
    // console.log(" this.localStorageService.satFiles", this.localStorageService.satFiles)
    this.checkDataToLoad();
    this.satValidarService.textToValidate = ''
    this.checkPreFormToNavigate();

  }

  navigateToStepByStepName(stepName: string) {
    const stepIndex = this.stepsSequence.findIndex(step => step.step_NM === stepName);
    if (stepIndex !== -1) {
      this.currentStepIndex = stepIndex;
    }
    this.stepToLoad = this.stepsSequence[this.currentStepIndex].step_NM;
    if (this.stepsSequence[this.currentStepIndex]['valid'] !== "S") {
      this.navegacao.continuarDisabled = true;
    }
    this.satValidarService.textToValidate = ''
    this.preForm.satFiles = this.localStorageService.satFiles

    // console.log(" this.localStorageService.satFiles", this.localStorageService.satFiles, this.preForm.satFiles)
    this.checkDataToLoad();
    this.checkPreFormToNavigate();
    //  console.log('navigateToStepByStepName')
  }

  temasServicosLoadedFalse() {
    this.temasServicosLoaded.next(false)
  }

  checkDataToLoad() {
    // this.getAreasTemas()

    //   console.log('this.getAreasTemas()')
    if (this.isEdicao === false) {
      //  this.getAreasTemas();
    }
    /*  this.temasServicosLoaded.subscribe((loaded: boolean) => {
       if (loaded === false) {
         console.log('this.getAreasTemas()')
         if (this.isEdicao === false) {
           this.getAreasTemas();
         }
       }
     }); */
    this.municipiosLoaded.subscribe((loaded: boolean) => {
      if (loaded === false) {
        this.getMunicipios();
      }
    });
    this.itsComplemLoaded.subscribe((loaded: boolean) => {
      if (loaded === false) {
        this.getItsByOrgi_Dk();
      }
    });


    if (this.stepToLoad === 'solicitante') {
      // this.getProcedimentosByMatricula();

      this.navegacao.voltarDisabled = true
    } else {
      this.navegacao.voltarDisabled = false
    }

    if (this.stepToLoad === 'procedimento') {
      // this.navegacao.continuarDisabled = true
      // this.getProcedimentosByMatricula();
      // this.getMunicipios();
    }

    if (this.stepToLoad === 'temas-servicos') {
      // this.getAreasTemas();
    }

    if (this.stepToLoad === 'documentos-upload') {
      this.localStorageService.currentDK = 3
    }

    if (this.stepToLoad === 'complementaridade') {
      this.localStorageService.currentDK = 2

    }

    if (this.stepToLoad === 'prioridade') {
      this.localStorageService.currentDK = 1
      if (this.prioridadesLoaded !== true) {
        this.getPrioridades();
      }
      if (this.prefLegaisLoaded !== true) {
        this.getPrefLegais();
      }
    }

  }

  // PREFORM SETTINGS *****************************************

  resetPreForm(block: string) {
    console.log('resetPreForm')
    if (block === 'all') {
      this.allValid(false)
      this.isEdicao = false
      this.editarSateDk = false
      this.editarSatAnexoNextOrder = 1
      this.tmsDkArray = []
      this.areasArr = []
      this.idsIntegraEditar = []
      this.currentStepIndex = 0;
      this.stepsLoaded = []
      this.navegacao.continuarDisabled = true;
      if (this.isEdicao === false) {
        this.stepToLoad = 'solicitante';
      }
      this.solicitanteMat = null;
      this.itsComplemLoaded.next(false);
      this.itsComplemArr = []
      this.municipiosArr = []
      this.municipiosLoaded.next(false)
      this.procedimentosUsuSolicit = [];
      this.procedimentosLoaded.next(false);
      this.procedimentoNRLoaded.next(false);
      this.temasServicosLoaded.next(false);
      this.sistemaOrigemPssoDk = 1
      this.procNR = ''
      this.procNROriginal = ''
      this.localStorageService.satFiles = []
      this.stepsSequence.forEach((step: any) => {
        step.valid = 'null'
      });
      this.localStorageService.satFiles = []

      this.preForm = {
        satFiles: [],
        satFilesInEdicao: [],
        solicitanteObj: null,
        procedimentoObj: null,
        municipioObj: null,
        municipiosArr: [],
        complementacaoObj: { isComplementacao: "N", docTecAnteriorObj: null },
        areasArr: [],
        areasArrDks: [],
        servicosArr: [],
        temasArrDks: [],
        excepcionalidadeObj: { inManifTecAnterior: "S", dsJustifAusenciaCompl: null },
        prefLegalObj: { plegDk: 1 },
        atendPrioritarioObj: {
          exceptPrioridade: '',
          isPrioritario: 'N',
          tipoPrazo: null,
          dtPrazoPrescr: null,
          dtPrazoPrior: null,
        },
        objetivoSolicitacaoStr: '',
      };

      /*   console.log("reseted preform", this.preForm)
        console.log("this.this.areasArr", this.areasArr)
        console.log('this.satDatabaseService.areasArr', this.satDatabaseService.areasArr)
        console.log("this.isEdicao", this.isEdicao) */
      this.getAreasTemas();

      this.checkPreFormToNavigate()




    } else {
      this.preForm[block] = null;
    }

  }

  allValid(valid: any) {
    if (valid === true) {
      this.stepsSequenceOriginal.forEach((step: any) => {
        if (step.step_NM !== 'enviarSat') {
          step.valid = 'S'
        }
      })
    }
    if (valid === false) {
      this.stepsSequenceOriginal.forEach((step: any) => {
        step.valid = 'null'
      })
    }
    // console.log(valid, this.stepsSequenceOriginal)
  }

  checkPreFormToNavigate() {
    let isValid = this.satValidarService.checkPreFormToNavigate(this.stepToLoad, this.preForm, this.isEdicao)
    this.navegacao.continuarDisabled = true
    if (isValid === true) {
      this.navegacao.continuarDisabled = false
      let formToDraft = this.preForm
      //  formToDraft['satFiles'] = []
      const preFormString = JSON.stringify(formToDraft);

      // localStorage.setItem('preFormDraft', preFormString);
    } else {
      this.navegacao.continuarDisabled = true


    }
    this.navegacao.textToContinue = this.satValidarService.textToValidate
    //  console.log(this.stepToLoad)
    this.validaStep()
    // console.log(this.stepsSequenceOriginal)

  }
  validaStep() {
    //  console.log(this.satValidarService.invalidStepArr)
    if (this.stepToLoad !== 'enviarSat') {

      this.stepsSequenceOriginal.forEach((stepSeq: any) => {
        //     console.log(stepSeq.valid)
        let checkStepInArr = this.satValidarService.invalidStepArr.includes(stepSeq.step_NM);
        //   console.log(checkStepInArr)
        if (checkStepInArr === true) {
          stepSeq.valid = 'N'
        } else {
          if (stepSeq.valid !== 'null') {
            stepSeq.valid = 'S'
          }
        }


      })
    }
    //console.log('validaserviceS',this.satValidarService.invalidStepArr)

  }

  setPreForm(block: string, content: any) {
    this.preForm[block] = content;

  }


  onToggleComplementacao(isComp: boolean) {
    if (isComp === true) {
      this.getItsByOrgi_Dk()
    } else {

    }
  }

  cancelarEdicao() {
    this.openDialog(this.editarSateDk)
    // this.resetPreForm('all')
    this.router.navigate(['/sat-lista']);
  }

  // GETS ' AND CHecks**************************************************

  getMatricula(matriculaToGet: any) {
    let matricula = '0000000' + matriculaToGet;
    matricula = matricula.substring(matricula.length - 8);
    return matricula
  }


  getMembroByMatricula(matriculaToCheck: any) {
    this.membroNaoEncontrado = false
    let matricula = this.getMatricula(matriculaToCheck)
    if (!matricula) {
      return this.preForm.solicitanteObj;

    }

    return this.satDatabaseService.getMembroByMatricula(matricula).subscribe(json => {
      let membro = json[0]
      if (membro !== undefined && membro !== null && membro.length !== 0) {
        //this.resetPreForm("all")
        this.preForm.solicitanteObj = {
          nome: membro.NOME,
          matricula: membro.MATRICULA,
        };
        this.navegacao.continuarDisabled = false
        this.membroNaoEncontrado = false
      } else {
        let data = {
          title: 'Membro não encontrado',
          content: 'Não foi possível encontrar o Membro com o número de matrícula: ' + matricula,
          subcontent: ' Feche esta caixa, confira o número da matrícula e tente novamente.',
        };

        this.preForm.solicitanteObj = null
        this.membroNaoEncontrado = true
        this.navegacao.continuarDisabled = true

      }
      this.firstLoad = false
      return this.preForm.solicitanteObj
    });
  }

  getProcedimentosByMatricula() {
    //
    /*    let matricula = this.getMatricula(this.preForm.solicitanteObj.matricula)
       if (this.procedimentosUsuSolicit.length === 0) {
         this.satDatabaseService.getProcedimentosByMatricula(matricula).subscribe(json => {
           this.procedimentosUsuSolicit = json;
           this.procedimentosLoaded.next(true);
         });
       } */
  }

  getProcNR(nunDocAndOrigem: any) {
    // console.log(nunDocAndOrigem)
    this.procedimentoNRLoaded.next(false);
    this.satDatabaseService.getProcNR(nunDocAndOrigem).subscribe(json => {
      //  console.log(json)
      if (json.length === 0) {
        this.preForm.procedimentoObj = null
      } else {
        if (json[0].assunto_PROCESSO === null) {
          json[0].assunto_PROCESSO = "não informado"
        }
        this.preForm.procedimentoObj = json[0]
        switch (this.preForm.procedimentoObj.sate_PSSO_DK) {
          case 3:
          case 4:
          case 5:
            this.isIntegra = true;
            break;
          case 1:
          case 2:
            this.isIntegra = false;
            break;
          default:
            // Handle other cases if needed
            break;
        }

        this.buildSteps()
        //  this.procNR = json[0]

      }
      this.procedimentoNRLoaded.next(true);
    });
  }

  buildSteps() {
    this.stepsSequence.forEach(stp => {
      if (stp.step_NM === 'documentos-integra' && this.isIntegra === false) {
        stp.step_NM = 'documentos-upload';
        stp.label = 'Upload de documentos'
      }
      if (stp.step_NM === 'documentos-upload' && this.isIntegra === true) {
        stp.step_NM = 'documentos-integra';
        stp.label = 'Documentos - Integra'
      }
    })
  }


  getMunicipios() {
    if (this.municipiosArr.length === 0) {
      this.satDatabaseService.getMunicipios().subscribe(json => {

        this.municipiosArr = json.CORP_CIDADE.sort(this.compare);
        this.municipiosArr.unshift({
          CIDA_NM_CIDADE: "ESTADO DO RIO DE JANEIRO",
          CIDA_DK: 0
        })
        this.municipiosLoaded.next(true);
      });
    }
  }
  compare(a: any, b: any) {
    if (a.CIDA_NM_CIDADE > b.CIDA_NM_CIDADE) {
      return 1;
    }
    if (a.CIDA_NM_CIDADE < b.CIDA_NM_CIDADE) {
      return -1;
    }
    return 0;
  }


  getItsByOrgi_Dk() {
    // if (this.preForm.complementacaoObj.isComplementacao === "S") {
    if (this.itsComplemArr.length === 0) {
      this.satDatabaseService.getItsByOrgiDk(1).subscribe(json => {
        this.itsComplemArr = json;
        //   console.log("this.itsComplemArr", this.itsComplemArr)
        this.itsComplemLoaded.next(true);
      });
    }
    //  }
  }




  getTemas() {

    if (this.areasArr.length === 0) {
      this.satDatabaseService.getTemas().subscribe(json => {
        for (const key in json) {
          if (Object.prototype.hasOwnProperty.call(json, key)) {
            const tema = json[key].tema;
            tema[0]['selected'] = false
            let objectToPush = tema[0]
            let servicos = tema[1]
            objectToPush['filteredServicosArr'] = []
            objectToPush['selectedServicos'] = []
            objectToPush['searchServico'] = ''
            let servArr = []
            for (const key in servicos) {
              if (Object.prototype.hasOwnProperty.call(servicos, key)) {
                const servico = servicos[key];
                servicos[key][0]['selected'] = false
                for (const keyDoc in servicos[key][0]['doc']) {
                  servicos[key][0]['DK_PARA_GRAVAR'] = servicos[key][0]['doc'][keyDoc].tmsDk
                  servicos[key][0]['doc'][keyDoc]['servicoNm'] = servicos[key][0].pstcNmServTec
                  servicos[key][0]['doc'][keyDoc]['idsArr'] = ['']
                }
                servArr.push(servicos[key][0])
              }
            }

            objectToPush['servicos'] = servArr
            objectToPush['filteredServicosArr'] = servArr
            this.areasArr.push(objectToPush)
          }
        }
        this.temasServicosLoaded.next(true);
      });
    }
  }



  getTemasServicos() {
    if (this.temasServicosArr.length === 0) {
      this.satDatabaseService.getTemasServicos().subscribe(json => {
        this.temasServicosArr = json;
        this.temasServicosLoaded.next(true);
      });
    }
  }



  getAreasTemas() {

    this.satDatabaseService.areasLoaded.subscribe((loaded: boolean) => {
      console.log("loaded", this.areasArr)
      if (loaded === true) {
        console.log("loaded", loaded)
        if (this.areasArr.length === 0) {
          this.areasArr = this.satDatabaseService.areasArr;
        }
        this.temasServicosLoaded.next(true);
        this.selectedServico();
      }
    });
    /*  if (this.areasArr.length === 0) {
       this.satDatabaseService.getAreasTemas().pipe(
         take(1)  // Automatically unsubscribes after one emission
       ).subscribe((json: any) => {
         this.areasArr = json.AreaServTec;
         console.log("carregou");
         this.temasServicosLoaded.next(true);
         this.selectedServico();
       });
     } */
  }
  selectedServico() {
    console.log("comecou------------")
    let areasArrDks = []
    let temasArrDks = []
    let areaTotal = []
    let pushArea

    if (this.areasArr.length === 0) {
      //  this.areasArr = this.satDatabaseService.getOriginalAreasTemas();
    }
    console.log('this.areasArr', this.areasArr)
    console.log('this.satDatabaseService.areasArr', this.satDatabaseService.areasArr)
    for (const area of this.areasArr) {
      //  console.log(area)
      area['selected'] = false
      pushArea = false
      let preArea: any = {
        area: area.ArstNmArea,
        temas: []
      }
      let pushTema
      for (const tema of area.Temas) {
        tema['selected'] = false
        pushTema = false
        let preTema: any = {
          tema: tema[0].tmanNmTema,
          servicos: []
        }
        for (const servico of tema[0].servTec) {

          let preServico: any = {
            servico: servico[0].pstcNmServTec,
            DK_PARA_GRAVAR: servico[0].tmsDk,
            tmsDk: servico[0].tmsDk,

            docsObrigatorios: [],
            docsDesejaveis: []
          }

          servico['docObrigatorios'] = []
          servico['docDesejaveis'] = []


          let isInEditar = false
          isInEditar = this.checkItemInArray(servico[0].tmsDk)

          if (isInEditar === true) {
            servico.selected = true
          }
          if (servico.selected === true) {
            areasArrDks.push(area.ArstDk)
            temasArrDks.push(tema[0].tmanDk)
            area['selected'] = true
            tema['selected'] = true
            console.log('isInEditar------------------', isInEditar)
            pushArea = true
            pushTema = true

            for (const documento of servico[0].doc) {
              if (this.isEdicao !== true) {
                documento['idsArr'] = ['']
              } else {
                let idsArrFromEditar: any = []
                for (const idIntegra of this.idsIntegraEditar) {
                  if (documento.dtstDk === idIntegra.dssaDtstDk) {
                    //   console.log("igual obrgatorio")
                    idsArrFromEditar.push(idIntegra.dssaIdDocIntegra)
                  }
                }

                if (idsArrFromEditar.length > 0) {
                  documento['idsArr'] = idsArrFromEditar
                } else {
                  documento['idsArr'] = ['']
                }


              }
              if (documento.dtstInObrigatorio === "S") {
                preServico.docsObrigatorios.push(documento)
                servico['docObrigatorios'].push(documento);
              }
              if (documento.dtstInObrigatorio === "N") {
                preServico.docsDesejaveis.push(documento)
                servico['docDesejaveis'].push(documento);
              }
            }
            preTema.servicos.push(preServico)
          }
          //   console.log(" preTema.servicos", preTema.servicos)
          // this.sortByPstcNmServTec(preTema.servicos)
        }
        if (pushTema === true) {
          preArea.temas.push(preTema)
        }

      }

      if (pushArea === true) {
        areaTotal.push(preArea)
      }

    }
    let idsAreasArr = [...new Set(areasArrDks)];
    let idsTemasArr = [...new Set(temasArrDks)];
    console.log("idsAreasArr", idsAreasArr)
    console.log("this.preForm", this.preForm)
    if (idsAreasArr !== null && idsAreasArr.length > 0) {
      this.preForm.areasArrDks = idsAreasArr
    } else {
      this.preForm.areasArrDks = []
    }
    this.preForm.temasArrDks = idsTemasArr
    this.preForm.areasArr = areaTotal
    console.log("this.preForm.areasArr", this.preForm.areasArr)

    this.checkPreFormToNavigate()
    console.log("acabou++++++++++++++")

  }
  sortByPstcNmServTec(array: any) {
    // console.log(array)
    return array.sort((a: any, b: any) => {
      if (a.pstcNmServTec < b.pstcNmServTec) {
        return -1;
      }
      if (a.pstcNmServTec > b.pstcNmServTec) {
        return 1;
      }
      return 0;
    });
  }
  checkItemInArray = (item: any) => {
    const index = this.tmsDkArray.indexOf(item);
    if (index !== -1) {
      this.tmsDkArray.splice(index, 1); // Remove the item from the array
      return true;
    }
    return false;
  };

  getPrioridades() {
    //  this.prioridadesLoaded.next(false);
    //  this.prioridadesArr = []
    if (this.prioridadesArr.length === 0) {
      this.satDatabaseService.getPrioridades().subscribe(json => {
        this.prioridadesArr = json;
        this.prioridadesLoaded.next(true);
      });
    }
  }

  getPrefLegais() {
    if (this.prefLegaisArr.length === 0) {
      this.satDatabaseService.getPrefLegais().subscribe(json => {
        this.prefLegaisArr = json;
        this.prefLegaisLoaded.next(true);
      });
    }
  }

  abrirProtocGestao(): void {
    const externalLink = 'https://intranet.mprj.mp.br/documents/10227/82319385/protocologestao1_2023documentossatv3f.pdf'; // Replace this with your actual external link
    window.open(externalLink, '_blank');
  }
  checkForm() {

  }


  enviarSat() {
    this.stepToLoad = "enviarSat"
    this.satState = { status: 'enviando', error: null, sateDk: null }
    let jsonData: any
    let testes
    testes = false



    const loggedUserJSON = localStorage.getItem('loggedUser');
    let loggedUserMatricula
    if (loggedUserJSON) {
      try {
        const loggedUser = JSON.parse(loggedUserJSON);
        loggedUserMatricula = loggedUser.matricula;
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    } else {
      // 'loggedUser' item does not exist in localStorage
    }


    jsonData = {

      "sateDtCadastro": " ",
      "sateInAprovada": "N",
      "sateDtAprovacao": " ",
      "sateOrgiDk": this.preForm.procedimentoObj.orgi_DK,
      "sateCdmatriculaSolicit": this.getMatricula(this.preForm.solicitanteObj.matricula),
      "sateCdmatriculaSat": this.getMatricula(this.matriculaSCA),
      "sateEmail": this.preForm.procedimentoObj.email,
      "sateTjraDk": null,
      "sateIdProtocolo": null,
      "sateDocuDk": null,
      "sateCidaDk": this.preForm.municipioObj.CIDA_DK,
      "sateInComplAnalise": this.preForm.complementacaoObj.isComplementacao,
      "sateInManifTecAnterior": this.preForm.excepcionalidadeObj.inManifTecAnterior,
      "sateDsJustifAusenciaCompl": null,
      "sateInPrioridade": this.preForm.atendPrioritarioObj.isPrioritario,
      "sateDtLimite": <any>null,
      "sateDsJustPrioridade": null,
      "sateDsDuvida": this.preForm.objetivoSolicitacaoStr,
      "sateNumCnmpIc": null,
      "sateNumIp": null,
      "sateItcnDk": null,
      "satePlegDk": 1,
      "satePssoDk": this.preForm.procedimentoObj.sate_PSSO_DK,
      "sateTpplDk": null,
      "satePtpsDk": 1,
      "sateNumDocumento": this.preForm.procedimentoObj.num_DOC,
      "sateAssuntoProcesso": this.preForm.procedimentoObj.assunto_PROCESSO,
      "sateTpDocumento": this.preForm.procedimentoObj.tipo_DOCUMENTO,
      "solAnaliseTecnicaAnexoList": [],
      "solAnaliseTecnServTecList": <any>[],
      "docTemaServTecSatList": <any>[],
      "solAnaliseTecnCidadeList": <any>[]
    }







    if (this.logadoIsMembro === true) {
      // jsonData.sateInAprovada = "S"
    }

    if (this.isEdicao === true) {
      jsonData.sateDk = this.editarSateDk
    }


    const SATE_PSSO_DK = this.preForm.procedimentoObj.sate_PSSO_DK;
    if (SATE_PSSO_DK === 1) {
      jsonData.sateDocuDk = this.preForm.procedimentoObj.proc_DK;
    } else if (SATE_PSSO_DK === 2) {
      jsonData.sateIdProtocolo = this.preForm.procedimentoObj.proc_DK;
    } else if (SATE_PSSO_DK === 3) {
      jsonData.sateNumCnmpIc = this.preForm.procedimentoObj.num_DOC;
    } else if (SATE_PSSO_DK === 4) {
      jsonData.sateTjraDk = this.preForm.procedimentoObj.proc_DK;
    } else if (SATE_PSSO_DK === 5) {
      jsonData.sateNumIp = this.preForm.procedimentoObj.num_DOC;
    }

    if (jsonData.sateInComplAnalise === "S") {
      jsonData.sateItcnDk = this.preForm.complementacaoObj.docTecAnteriorObj.itcn_dk
    }

    if (this.preForm.excepcionalidadeObj.inManifTecAnterior === "N") {
      jsonData.sateDsJustifAusenciaCompl = this.preForm.excepcionalidadeObj.dsJustifAusenciaCompl
    }

    if (this.preForm.atendPrioritarioObj.dtPrazoPrior !== null) {
      jsonData.sateDtLimite = this.preForm.atendPrioritarioObj.dtPrazoPrior.format('YYYY-MM-DD HH:mm:ss')
    }

    if (this.preForm.atendPrioritarioObj.isPrioritario === "S") {
      jsonData.sateDtLimite = moment(this.preForm.atendPrioritarioObj.dtPrazoPrior).format('YYYY-MM-DD HH:mm:ss')
      if (this.preForm.atendPrioritarioObj.tipoPrazo.tpplDk === 4) {
        jsonData.sateDsJustPrioridade = this.preForm.atendPrioritarioObj.exceptPrioridade
      }
    }

    if (this.preForm.atendPrioritarioObj.tipoPrazo !== null) {
      jsonData.sateTpplDk = this.preForm.atendPrioritarioObj.tipoPrazo.tpplDk
    }



    //   jsonData.sateTpplDk = null //APAGAR
    //   jsonData.sateDtLimite = null //APAGAR
    //   jsonData.sateDsJustPrioridade = null //APAGAR
    //   jsonData.sateInPrioridade = "N" //APAGAR


    if (this.preForm.prefLegalObj !== null) {
      jsonData.satePlegDk = this.preForm.prefLegalObj.plegDk
    } else {
      jsonData.satePlegDk = 1
    }


    // MUNICIPIOS
    const ids = this.preForm.municipiosArr.map((CIDA_DK: any) => CIDA_DK);
    const filteredMun = this.preForm.municipiosArr.filter((CIDA_DK: any, index: any) =>
      !ids.includes(CIDA_DK, index + 1));
    for (const municipio of filteredMun) {
      if (municipio.CIDA_DK !== 0) {
        jsonData.solAnaliseTecnCidadeList.push({ "sacdCidaDk": municipio.CIDA_DK })
      }
    }


    // console.log(jsonData.solAnaliseTecnCidadeList)
    //ANEXOS
    let anexos = this.preForm.satFiles
    //let anexos:any =[]
    let anexosArrToUpload: any = []
    let hasTpaxDk5 = false
    let ordem = 1
    if (this.isEdicao === true) {
      ordem = this.editarSatAnexoNextOrder
    }
    let totalSize = 0
    for (const file of anexos) {
      totalSize += file.size;
      const parts = file.base64.split(",");
      // Get the second part (index 1)
      const base64Data = parts[1];
      let anexoObj = {

        "saaxNmArquivo": file.name,
        "saaxNmExtensaoArquivo": this.getCharactersAfterLastDot(file.name),
        "saaxNrOrdem": ordem,
        "saaxArquivoAnexo": base64Data,
        "saaxSateDk": "",
        "saaxInObrigatorio": file.saaxInObrigatorio === true ? "S" : "N",
        "saaxDsObservacao": file.saaxDsObservacao,
        "saaxTpaxDk": file.tpaxDk,
        "saaxTpaxDkList": {
          "tpaxDk": file.tpaxDk,
          "tpaxNmTipoSatAnexo": ""
        },
        "saaxNmMimeType": file.type


      }
      if (file.tpaxDk === 5) {
        hasTpaxDk5 = true
      }
      anexosArrToUpload.push(anexoObj)
      ordem++
    }
    jsonData.solAnaliseTecnicaAnexoList = anexosArrToUpload
    //jsonData.solAnaliseTecnicaAnexoList.push(anexosArrToUpload[0]) //='[' + anexosArrToUpload[0] +']';
    this.preForm.satFiles.push(anexosArrToUpload);


    //Complemetaridade

    if (this.preForm.excepcionalidadeObj.inManifTecAnterior === 'S') {
      this.preForm.excepcionalidadeObj.dsJustifAusenciaCompl = null
    } else {
      if (hasTpaxDk5 === true) {

        if (this.preForm.excepcionalidadeObj.dsJustifAusenciaCompl === null || this.preForm.dsJustifAusenciaCompl === '') {
          this.preForm.excepcionalidadeObj.dsJustifAusenciaCompl = 'Justificativa realizada por meio de anexo(s)'
        }
      }
    }

    jsonData.sateDsJustifAusenciaCompl = this.preForm.excepcionalidadeObj.dsJustifAusenciaCompl



    // SERVICOS E DOCUMENTOS
    let areasArr = this.preForm.areasArr
    for (const area of areasArr) {
      for (const tema of area.temas) {
        for (const servico of tema.servicos) {
          jsonData.solAnaliseTecnServTecList.push(
            {
              "sastDk": "",
              "sastSateDk": "",
              "sastTmstDk": servico.DK_PARA_GRAVAR
            })
          if (this.isIntegra === true) {
            for (const docObrg of servico.docsObrigatorios) {
              for (const idIntegra of docObrg.idsArr) {
                if (idIntegra === '') {
                } else {
                  jsonData.docTemaServTecSatList.push(
                    {
                      "dssaIdDocIntegra": idIntegra,
                      "dssaDtstDk": docObrg.dtstDk
                    })
                }
              }
            }
            for (const docObrg of servico.docsDesejaveis) {
              for (const idIntegra of docObrg.idsArr) {
                if (idIntegra !== '') {
                  jsonData.docTemaServTecSatList.push(
                    {
                      "dssaIdDocIntegra": idIntegra,
                      "dssaDtstDk": docObrg.dtstDk
                    })
                }

              }
            }
          }
        }
      }
    }



    // Extract unique sastTmstDk values
    const uniqueTmstDks = jsonData.solAnaliseTecnServTecList.map((obj: any) => obj.sastTmstDk).filter((value: any, index: any, self: any) => self.indexOf(value) === index);

    // Construct the resulting array with unique objects based on sastTmstDk
    const uniqueTmstObjects = uniqueTmstDks.map((sastTmstDk: any) => {
      const existingObject: any = jsonData.solAnaliseTecnServTecList.find((obj: any) => obj.sastTmstDk === sastTmstDk);
      return {
        sastDk: '', // Keep it as empty string
        sastSateDk: '', // Keep it as empty string
        sastTmstDk: sastTmstDk // Use the unique sastTmstDk value
      };
    });

    // Replace the original array with the unique objects array
    jsonData.solAnaliseTecnServTecList = uniqueTmstObjects;








    //  console.log('jsonData:', jsonData);
    // return






    //return
    // Call the service to create a new Sate record


    if (this.isEdicao === true) {
      //  console.log('jsonData:', jsonData);
      this.satDatabaseService.editarSat(jsonData).subscribe({
        next: data => {
          if (data.type === HttpEventType.UploadProgress) {
            // Calculate and update upload progress
            this.uploadProgress = Math.round((100 * data.loaded) / data.total);
          } else if (data instanceof HttpResponse) {
            this.satState = { status: 'editada', error: null, sateDk: data.body.sateDk }
            //   console.log('Sate record updated successfully:', data.body);
            this.createSateRascunho(jsonData, data.body)
            setTimeout(() => {
              this.openDialog(data.body)

              this.router.navigate(['/sat-lista']);
            }, 1000);
          }



        },
        error: error => {
          this.createSateRascunho(jsonData, error)
          console.error('There was an error!', error);
        }
      });
    } else {

      console.log('Arquivos', anexosArrToUpload);
      this.satDatabaseService.createSate(jsonData).subscribe(
        (event: any) => {
          debugger
          console.log('EVENTO', event.type)
          if (event.type === HttpEventType.UploadProgress) {
            // Calculate and update upload progress
            this.uploadProgress = Math.round(100 * event.loaded / event.total);
          } else
          if (event.type === HttpEventType.Response) {

              const sateDK = event.body.sateDk;
            /*
            if (sateDK != null || sateDK != undefined) {

              for (var i = 1; i < anexosArrToUpload.length; i++) {
                const arquivox = []
                arquivox.push(anexosArrToUpload[i])
                const s = arquivox[0].saaxNmArquivo
                this.satState = { status: 'enviando', error: null, sateDk: null }

                this.satDatabaseService.uploadAnexos(arquivox, sateDK).subscribe(
                  (event: any) => {
                    if (event.type === HttpEventType.UploadProgress) {
                      // Calculate and update upload progress
                      this.uploadFileProgress = Math.round(100 * event.loaded / event.total);
                      this.messagefile = "Enviado arquivo: " + i + " de " + anexosArrToUpload.length + 1
                    } else if(event.type === HttpEventType.Response) {
                      debugger
                      console.log('Resposta Anexo Recebida!', event.body);
                      this.messagefile = "Upload concluido"
                      this.satState = { status: 'submetida', error: null, sateDk: event.body.sateDk }
                    }
                   //uploadFileProgress //console.log('Rascunho gravado:', event);

                  },
                  (error) => {
                    // Handle error responses here
                    anexosArrToUpload[i].saaxNmArquivo;
                    //console.error('Error creating anexo:', error);
                    this.toast.error('Erro ao Gravar Anexo ' + anexosArrToUpload[i].saaxNmArquivo);
                    this.satState = { status: 'error', error: error.message, sateDk: null };
                  }
                )
                // continue
              }

            }
            */
            if (this.logadoIsMembro === true) {
              this.satState = { status: 'submetendo', error: null, sateDk: event.body.sateDk }
              this.satDatabaseService.submeterSat(event.body.sateDk).subscribe({
                next: data => {


                  setTimeout(() => {
                    this.createSateRascunho(jsonData, data)
                    //this.satState = { status: 'submetida', error: null, sateDk: event.body.sateDk }
                  }, 1000);
                },
                error: error => {
                  this.createSateRascunho(jsonData, error)
                  console.error('There was an error!', error);
                }
              });
            } else {

              setTimeout(() => {
                this.createSateRascunho(jsonData, event.body)
                this.satState = { status: 'enviada', error: null, sateDk: event.body.sateDk }
              }, 1000);
            }
            // this.satState = { status: 'enviada', error: null, sateDk: event.body.sateDk }
          }
        },
        (error) => {
          // Handle error responses here
          this.createSateRascunho(jsonData, error)
          console.error('Error creating Sate record:', error);
          this.satState = { status: 'error', error: error.message, sateDk: null };
        }
      );
    }

  }

  openDialog(selectedSat: any) {

    const dialogRef = this.dialog.open(SatDetalhesComponent, {
      autoFocus: false,
      maxHeight: '90vh',//you can adjust the value as per your view
      data: { sat: selectedSat },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      //  console.log(result)
      setTimeout(() => {
        if (result.event !== 'editar') {
          this.router.navigate(['/sat-lista']);
        } else {
          this.currentStepIndex = 0
          this.stepToLoad = 'solicitante'
          this.router.navigateByUrl('/sat-editar');
        }

      }, 1000);

    });
  }

  createSateRascunho(jsonData: any, response: any) {

    this.sateRascunho.rsatCdmatricula = this.getMatricula(this.matriculaSCA)

    this.preForm.satFiles.forEach((element: any) => {
      element.base64 = "suprimido"
    });

    jsonData.solAnaliseTecnicaAnexoList.forEach((element: any) => {
      element.saaxArquivoAnexo = "suprimido"
    });

    let rascunhoObj: any = {
      formObj: this.preForm,
      dataObj: jsonData,
      responseObj: response
    }

    this.sateRascunho.rsatRascunho.push(rascunhoObj)

    console.log(this.sateRascunho)



    this.satDatabaseService.createRascunho(this.sateRascunho).subscribe(
      (event: any) => {
        console.log('Rascunho gravado:', event);
      },
      (error) => {
        // Handle error responses here
        console.error('Error creating rascunho:', error);
        this.satState = { status: 'error', error: error.message, sateDk: null };
      }
    );
  }

  getCharactersAfterLastDot(inputString: any) {
    const lastDotIndex = inputString.lastIndexOf('.');
    if (lastDotIndex !== -1 && lastDotIndex < inputString.length - 1) {
      return inputString.substring(lastDotIndex + 1);
    }
    return null; // Return null if there is no dot or it's the last character.
  }



  setPreformToTest() {






    this.preForm = {}

    this.localStorageService.satFiles = []


    this.stepToLoad = 'resumo'
  }

}
